@import "base/mixins";

@font-face {
  font-family: founders-grotesk-regular;
  src: url(https://cdn.shopify.com/s/files/1/0274/3641/7123/t/2/assets/founders-grotesk-web-regular.woff2) format("woff2");
}

@font-face {
  font-family: founders-grotesk-medium;
  src: url(https://cdn.shopify.com/s/files/1/0274/3641/7123/t/2/assets/founders-grotesk-web-medium.woff2) format("woff2");
}

:root {
  --header-height: 65px;

  @include breakpoint(lg) {
    --header-height: 70px;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  @include font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2 {
  margin: 0;
}

.site-header {
  position: sticky;
  background: #752e4a;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  height: var(--header-height);
  top: 0;
  z-index: 20;
}

.site-header-logo {
  width: 100%;
}

.site-header-logo a {
  display: block;
  color: white;
  width: 40px;
  margin: auto;

  @include breakpoint(md) {
    width: 470px;
  }
}

.site-header-logo a svg:first-child {
  display: block;

  @include breakpoint(md) {
    display: none;
  }
}

.site-header-logo a svg:last-child {
  display: none;

  @include breakpoint(md) {
    display: block;
  }
}

.container {
  padding-left: 6vw;
  padding-right: 6vw;

  @include breakpoint(xl) {
    padding-left: 12vw;
    padding-right: 12vw;
  }
}

.section-intro {
  height: calc(300vh - var(--header-height));
}

.intro-block {
  color: white;
  text-transform: uppercase;
  height: calc(25vh - (var(--header-height) / 4));
  overflow: hidden;
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  top: var(--header-height);
  width: 100%;

  span {
    @extend .container;
    @include fluid-type(90px, 230px);
    display: block;
    position: relative;
    width: 100%;
  }

  sup {
    top: -0.65em;
    left: -0.03em;
    font-size: 50%;
  }
}

.intro-block-1 {
  @include font-primary-medium;
  background: #752e4a;
  top: var(--header-height);
}

.intro-block-2 {
  @include font-primary-medium;
  background: #ff8200;
  top: calc((25vh - (var(--header-height) / 4)) + var(--header-height));
}

.intro-block-3 {
  background: #db0033;
  top: calc((25vh - (var(--header-height) / 4)) * 2 + var(--header-height));
}

.intro-block-4 {
  background: #ff8773;
  top: calc((25vh - (var(--header-height) / 4)) * 3 + var(--header-height));
}

.content-block {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  z-index: 10;
}

.content-block-1 {
  background: #752e4a;
}

.content-block-2 {
  background: #db0033;
}
