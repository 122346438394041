$breakpoints: (
  'md': 768px,
  'lg': 1024px,
  'xl': 1280px,
  'xxl': 1440px
) !default;

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  @else if $breakpoint == md-only {
    @media (min-width: map-get($breakpoints, md)) and (max-width: map-get($breakpoints, lg) - 1px) {
      @content;
    }
  }
  @else {
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

@mixin font-primary {
  font-family: "founders-grotesk-regular", sans-serif;
}

@mixin font-primary-medium {
  font-family: "founders-grotesk-medium", sans-serif;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

$fluid-min-width: 320px;
$fluid-max-width: 1440px;

@mixin fluid-type($min-font-size, $max-font-size, $min-vw: $fluid-min-width, $max-vw: $fluid-max-width) {
  @include fluid-font($min-font-size, $max-font-size, $min-vw: $fluid-min-width, $max-vw: $fluid-max-width);
  line-height: 0.75em;
}

@mixin fluid-font($min-font-size, $max-font-size, $min-vw: $fluid-min-width, $max-vw: $fluid-max-width) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
